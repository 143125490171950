import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import { Links } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import UserIcon from "@material-ui/icons/Person";
import ShoppingCartOutlined from "@material-ui/icons/ShoppingCartOutlined";
import Cookies from 'js-cookie';
import ModalAccion from '../Generales/Modales/ModalAccion.jsx';
import Globales from "utils/Globales";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Button from "components/CustomButtons/Button.jsx";
import headerLinksStyle from "assets/jss/material-kit-pro-react/components/headerLinksStyle.jsx";
import { CircularProgress, Badge } from '@material-ui/core';

function VerificarIdentidad(props) {
  const { classes, dropdownHoverColor } = props;
  const [liveDemo, setLiveDemo] = useState(false);
  const [cargando, setCargando] = useState(true);
  const [Valido, setValido] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [TokenAuth0, setTokenAuth0] = useState("");

  const [params, setParams] = useState({})

  useEffect(async() => {
    let vinculacion = await getParameterByName("Vinc") == "true" ? true : false
    let expira = new Date(parseInt(await getParameterByName("expira")));
    let correo = await getParameterByName("correo")
    let idTokenSocialNetwork = await getParameterByName("idTokenSocialNetwork")
    setTokenAuth0(idTokenSocialNetwork)
    
    console.log({
      expira,
      FechaActual: new Date(),
      result:expira.getTime() < new Date().getTime(),
    });
    
    if (expira.getTime() < new Date().getTime()) {
    // if (true) {
      setValido(false)
      setMensaje("El enlace de verificación ha caducado")
      setCargando(false)
    } else {
      if (vinculacion) {
        VincularCuentas(idTokenSocialNetwork)
      } else {
        let NombreUsuarioUnico = correo.split("@")[0] + idTokenSocialNetwork.split("|")[0]
        let usuarioNuevo = {
          Nombre: "",
          Correo: correo,
          TipoUsuario: "Cliente",
          ContrasenaRegistro: "",
          ConfirmacionContrasena: "",
          UsuarioRegistro: NombreUsuarioUnico,
          idTokenSocialNetwork: idTokenSocialNetwork,
          TokenSocialNetwork: idTokenSocialNetwork,
          RedSocial: 0
        };
        RegistrarUsuario(usuarioNuevo)
      }
    }
  }, [])

  async function getParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
      results = regex.exec(window.location.href);
    return results == null ? null : decodeURIComponent(results[1].replace(/\+/g, " "));
  }
  const handleClickOpen = (modal) => {
    setLiveDemo({ [modal]: true });
  };

  const handleClose = (modal) => {
    setLiveDemo({ [modal]: false });
  };

  const openModal = () => {
    setLiveDemo(true);
  };

  const irMenuPrincipal = () => {
    setTimeout(() => {
      window.location.href = '/#/';
    }, 5000);
  };

  const eventoModal = (e) => {
    if (!e) {
      setLiveDemo(false);
      return;
    }
    // cerrarSesion();
  };

  const Login = (token) => {
    fetch(Globales.Url + "IniciarSesionCliente", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token
      },
      body: JSON.stringify({
        RedSocial: token
      })
    })
      .then(res => res.json())
      .then(data => {
        if (data.status !== 404) {
          // Actualiza el estado con la respuesta del servidor
          console.log(data);

          if (data.response === true) {
            var sesion = {
              id: data.usuario._id,
              RedSocial: token,
              Carrito: data.carrito,
              ListaCarrito: data.usuario.ListaCarrito,
              ListaDeseos: data.usuario.ListaDeseos,
              Usuario: data.usuario.Usuario,
              Nombre: data.usuario.Nombre,
              Correo: data.usuario.Correo,
              Telefono: data.usuario.Telefono,
              TipoUsuario: data.usuario.TipoUsuario,
              Token: data.token,
              IndicadorTerco: data.usuario.IndicadorTerco,
            };
            Cookies.set("UsuarioTerco", JSON.stringify(sesion));
            Cookies.set("Id", data.usuario._id);
            Cookies.set("token", data.token);
            localStorage.setItem("idUser", data.usuario._id);
            Globales.token = "Bearer " + data.token;
            let dt = new Date();
            localStorage.setItem(
              "expira",
              new Date(dt.getTime() + 20 * 60000)
            );
            
            setCargando(false);
            setValido(true)
          irMenuPrincipal()
            
          }
        }
      })
      .catch(error => {
        console.error("Error:", error);
      });
  };

  const RegistrarUsuario = (usuarioNuevo) => {
    fetch(Globales.Url + "UsuarioClientes/UsuarioClientes/RegistrarCliente", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
      body: JSON.stringify(usuarioNuevo)
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Error en la solicitud');
        }
        return res.json();
      })
      .then((data) => {
        if (data.response === true) {
          Login(usuarioNuevo); 
        } else {
          console.error('Error en el registro:', data.message); // si hay un mensaje de error en la respuesta
        }
      })
      .catch((err) => console.error(err));
  };

  const VincularCuentas = (token) => {
    fetch(
      Globales.Url + "UsuarioClientes/UsuarioClientes/ValidarVinculacionExitosa",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: Globales.token,
        },
        body: JSON.stringify({ idTokenSocialNetwork: token }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.data != null) {
          setMensaje(data.mensaje)
          Login(token);
        } else {
          setValido(false)
          setMensaje(data.mensaje)
          setCargando(false)
        }
      })
      .catch((err) => console.error(err));
  };

  const eliminarTodasLasCookies = () => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }

    localStorage.clear();
    sessionStorage.clear();
  }

  const CambiarRuta = (ruta) => {
    window.location.href = ruta;
  }

  const popover = {
    height: "calc(100vh - 60px)",
    width: '100vw',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  }

  return (
    <div style={popover}>
      {
        cargando ?
          <ListItem className={classes.listItem}>
            <CircularProgress size={70} thickness={5} style={{ color: "black" }} />
          </ListItem>
          :
          Valido ?
            <div>
              <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
              </svg>
              <h3>{mensaje}</h3>
            </div>
            :
            <div>
              <div class="container_Error">
                <div class="circle_Error">
                  <div class="error" />
                </div>
              </div>
              <h3>{mensaje}</h3>
            </div>
      }
      <div id="">
        <ModalAccion
          Titulo='Cerrar Sesión'
          body={'¿Seguro que deseas cerrar sesión ' + JSON.parse(Cookies.get('UsuarioTerco')).Usuario + '?'}
          liveDemo={liveDemo}
          eventClick={eventoModal}
        />
      </div>
    </div>
  );
}

export default withStyles(headerLinksStyle)(VerificarIdentidad);