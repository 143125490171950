import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components";

// Definir estilos para el botón
const StyledButton = styled.button`
  /* Agrega tus estilos personalizados aquí */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: inherit;
  display: flex;
  // padding: 0.75rem 1.25rem 0.75rem 0.75rem;
  padding: 0.9rem 1.25rem 1rem 0.75rem;
  text-decoration: none;
  background: none;
  font-size: 13px;
`;

// Función para eliminar todas las cookies
const eliminarTodasLasCookies = () => {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
  }

  localStorage.clear();
  sessionStorage.clear();
}

const cerrarSesion = () => {
  eliminarTodasLasCookies()
}

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <StyledButton
      onClick={() =>{
        cerrarSesion()
        logout({ logoutParams: { returnTo: window.location.origin } })
      }}
    >
      CERRAR SESIÓN
    </StyledButton>
  );
};

export default LogoutButton;
